import create from "zustand";
import { StoreStateType } from "../types/store";
import { devtools } from "zustand/middleware";
import { SizeType } from "../types/size";

const useStore = create<StoreStateType>()(
  devtools(
    (set, get): StoreStateType => ({
      setKeepFrame: async (id, keepFrame) => {
        await fetch("/api/images", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imageId: id,
            keepFrame: keepFrame,
          }),
        });
        return set((state) => ({
          loading: false,
          images: state.images.map((image) => {
            if (image._id === id) {
              image.keepFrame = keepFrame;
            }
            return image;
          }),
        }));
      },
      addImage: (image) => {
        // const newImages = new Array(amount).fill(image);
        set((state) => ({
          images: [...state.images, image],
        }));
      },
      setSentMail: async (organizerId: string, isStandhouder) => {
        if (isStandhouder) {
          // for standhoduers organizerId is the link of the standhouder
          const standhouders = get().standhouders;
          set({
            standhouders: standhouders.map((standhouder) =>
              standhouder.link === organizerId
                ? { ...standhouder, emailSent: true }
                : standhouder
            ),
          });
          await fetch(`/api/standhouders/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ link: organizerId, emailSent: true }),
          });
        } else {
          const organizers = get().organizers;
          set({
            organizers: organizers.map((organizer) =>
              organizer._id === organizerId
                ? { ...organizer, emailSent: true }
                : organizer
            ),
          });
          await fetch(`/api/publicOrganizers/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ _id: organizerId, emailSent: true }),
          });
        }
      },
      deleteInfoToStandhouder: async (info, standhouderId) => {
        await fetch("/api/info", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            standhouderId,
            driveId: info.driveId,
            itemId: info.itemId,
          }),
        });
        set({
          standhouders: get().standhouders.map((x) => {
            if (x.link === standhouderId) {
              return {
                ...x,
                infos: (x.infos || []).filter((y) => y.itemId !== info.itemId),
              };
            }
            return x;
          }),
          loading: false,
        });
      },
      addInfoToStandhouder: (standhouderId, info) => {
        const { standhouders } = get();
        const standhouder = standhouders.find(
          (standhouder) => standhouder.link === standhouderId
        );
        if (standhouder) {
          standhouder.infos = [...(standhouder.infos || []), info];
        }
        set({ standhouders });
      },
      deleteImage: async (image) => {
        await fetch(`/api/images`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: image._id,
            deleteFromDrive: image.preview,
          }),
        });
        return set({
          loading: false,
          images: get().images.filter((x) => x._id !== image._id),
        });
      },
      images: [],
      error: "",
      setError: (error: string) => set((state) => ({ error })),
      suppliers: [],
      addSupplier: async (supplier) => {
        const newSupplier = await fetch("/api/suppliers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplier),
        });
        const newSupplierData = await newSupplier.json();
        set({
          suppliers: [...get().suppliers, newSupplierData],
          loading: false,
        });
      },
      deleteSupplier: async (supplierId) => {
        await fetch(`/api/suppliers`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ supplierId }),
        });
        set((state) => ({
          ...state,
          suppliers: state.suppliers.filter(
            (supplier) => supplier._id !== supplierId
          ),
          loading: false,
        }));
      },
      deleteSize: async (sizeId) => {
        await fetch(`/api/sizes`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sizeId }),
        });
        set((state) => ({
          ...state,
          sizes: state.sizes.filter((size) => size._id !== sizeId),
          loading: false,
        }));
      },
      clearData: () => {
        return set({ organizers: [], isAdmin: false });
      },
      isAdmin: false,
      loadDataStandhouder: async (standhouderId) => {
        const { dataLoaded } = get();
        if (dataLoaded) return set({ loading: false });

        const response = await fetch(
          "/api/loadStandhouderData/" + standhouderId
        );
        if (response.status === 404) {
          console.log("???? not found");
          set({
            loading: false,
            error: "Deze standhouder is niet gevonden",
            organizers: [],
            standhouders: [],
            sizes: [],
            dataLoaded: true,

            images: [],
            isAdmin: false,
          });

          // redirect to 404
          location.href = "/404";

          return;
        }
        const data = await response.json();

        set({
          organizers: data.organizers,
          standhouders: data.standhouders,
          sizes: data.sizes,
          dataLoaded: true,
          loading: false,
          images: data.images,
          isAdmin: data.isAdmin,
        });
      },
      loadDataOrganizer: async (organizerId) => {
        const { dataLoaded } = get();
        if (dataLoaded) return set({ loading: false });

        const response = await fetch("/api/loadOrganizerData/" + organizerId);
        const data = await response.json();

        set({
          organizers: data.organizers,
          standhouders: data.standhouders,
          sizes: data.sizes,
          dataLoaded: true,
          loading: false,
          images: data.images,
          isAdmin: data.isAdmin,
        });
      },
      loadData: async () => {
        const { dataLoaded } = get();
        if (dataLoaded) return set({ loading: false });

        const response = await fetch("/api/loadAdminData");
        const sizesRaw = await fetch("/api/sizes");
       
        const organizersRaw = await fetch("/api/organizers");
        const suppliersRaw = await fetch("/api/suppliers");
        const sizes = await sizesRaw.json();
        const data = await response.json();
        const organizers = await organizersRaw.json();
        const suppliers = await suppliersRaw.json();
        
        // const orgResponse = await fetch("/api/organizers");
        // const sizesResponse = await fetch("/api/sizes");
        // const suppliersResponse = await fetch("/api/suppliers");
        // const standhoudersResponse = await fetch("/api/standhouders");
        // const imagesResponse = await fetch("/api/images");
        // const sizes = await sizesResponse.json();
        // const organizers = await orgResponse.json();
        // const suppliers = await suppliersResponse.json();
        // const standhouders = await standhoudersResponse.json();
        // const images = await imagesResponse.json();
        set({
          sizes: sizes,
          organizers: organizers.organizers,
          suppliers: suppliers,
          isAdmin: response.status === 200,
          dataLoaded: true,
          standhouders: data.standhouders,
          images: data.images,
          loading: false,
        });
      },
      deleteOrganizer: async (organizerId: string) => {
        await fetch(`/api/organizers/`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            organizerId,
          }),
        });

        set({
          organizers: get().organizers.map((org) =>
            org._id === organizerId ? { ...org, active: false } : org
          ),
          loading: false,
        });
        
      },
      editOrganizer: async (org) => {
        const editedOrg = await fetch(`/api/organizers`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(org),
        });
        const editedOrgData = await editedOrg.json();
        set({
          organizers: get().organizers.map((organizer) => {
            if (organizer._id === org._id) {
              return editedOrgData;
            }
            return organizer;
          }),
          loading: false,
        });
      },
      editStandhouder: async (standhouder) => {
        const editedOrg = await fetch(`/api/standhouders`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(standhouder),
        });
        const editedOrgData = await editedOrg.json();
        set({
          standhouders: get().standhouders.map((sh) => {
            if (sh._id === standhouder._id) {
              return editedOrgData;
            }
            return sh;
          }),
          loading: false,
        });
      },
      addSize: async (size) => {
        const newSize = await fetch("/api/sizes", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(size),
        });
        const newSizeJson = (await newSize.json()) as SizeType[];
        set({
          sizes: [...get().sizes, ...newSizeJson],
          loading: false,
        });
      },
      editSize: async (size) => {
        await fetch("/api/sizes", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(size),
        });
        set((state) => ({
          ...state,
          sizes: state.sizes.map((s) => {
            if (s._id === size._id) {
              return size;
            }
            return s;
          }),
          loading: false,
        }));
      },
      dataLoaded: false,

      organizers: [],
      standhouders: [],
      setStandhouders: (standhouders) => {
        return set({
          standhouders,
        });
      },
      sizes: [],
      addStandhouder: async (standhouder) => {
        const addStandhoudersResponse = await fetch("/api/standhouders", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(standhouder),
        });
        const addedStandhoudersJson = await addStandhoudersResponse.json();
        console.log("addedStandhouders", addedStandhoudersJson);
        const addedStandhouders = addedStandhoudersJson.standhouder;

        return set((state) => ({
          ...state,
          standhouders: Array.isArray(addedStandhouders)
            ? [...state.standhouders, ...addedStandhouders]
            : [...state.standhouders, addedStandhouders],
          loading: false,
        }));
      },
      addOrganizer: async (organizer) => {
        const newOrgResponse = await fetch("/api/organizers", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(organizer),
        });
        const newOrg = await newOrgResponse.json();
        set((state) => ({
          ...state,
          organizers: [...state.organizers, newOrg.company],
          loading: false,
        }));
      },
      loading: false,
      setLoading: (loading: boolean) => set((state) => ({ ...state, loading })),
      status: "",
      setStatus: (status) => set({ status }),
    })
  )
);

export default useStore;
